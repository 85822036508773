@media screen and (max-width: 991px) {
  .custom-seats .seats-grid {
    grid-template-columns: 1.3fr 2fr;
  }
  .custom-seats .cabin-box {
    grid-template-columns: 1.3fr 2fr;
  }
}
@media screen and (max-width: 768px) {
  .custom-seats .cabin-box .box .tablee {
    font-size: 14px;
  }
  .uniqclvalue {
    width: 20rem;
    height: 15rem;
    top: 16rem;
    left: 50%;
  }
  .uniqclvalue #displayvalue {
    font-size: 5rem;
    font-weight: 700;
    color: #0a0 !important;
  }
}
@media screen and (max-width: 576px) {
  .uniqclvalue {
    width: 15rem;
    height: 10rem;
    top: 16rem;
    left: 50%;
  }
  .uniqclvalue #displayvalue {
    font-size: 3rem;
    font-weight: 600;
    color: #0a0 !important;
  }
  .custom-seats .seats-grid {
    grid-template-columns: 1.2fr 2fr;
  }
  .custom-seats .cabin-box {
    grid-template-columns: 1.2fr 2fr;
  }
  .custom-seats .seats-grid .seat-box .seat label {
    font-size: 11px;
  }
  .custom-seats .seats-grid .seat-box .img {
    max-width: 15px;
  }
  .custom-seats .cabin-box .box .tablee {
    font-size: 12px;
  }
  .custom-seats .path-line .box3 .entrance {
    font-size: 11px;
  }
  .custom-seats .path-line {
    grid-template-columns: 1.3fr 2fr 1.3fr;
  }
  .cl-custom-check + .cl-custom-check-label {
    width: 66px;
  }
  .acstd98 .cl-custom-check + .cl-custom-check-label {
    width: 55px;
  }
  .custom-seats .seats-grid .seat-box {
    grid-gap: 0.3rem;
  }
}
@media screen and (max-width: 450px) {
  .cl-custom-check + .cl-custom-check-label {
    width: 50px;
  }
  .acstd98 .cl-custom-check + .cl-custom-check-label {
    width: 45px;
  }
}
@media screen and (max-width: 390px) {
  .cl-custom-check + .cl-custom-check-label {
    width: 40px;
  }
  .acstd98 .cl-custom-check + .cl-custom-check-label {
    width: 35px;
  }
}/*# sourceMappingURL=responsive.css.map */