.react-datepicker-ignore-onclickoutside {
  width: 100%;
  border-color: #005DA8;
}

.customDate {
  width: 100%;
  cursor: pointer;
  height: 46px;

  box-shadow: 0 0.0625rem 0.125rem rgb(0 0 0 / 5%);
  border-radius: 5px;
  border: 1px solid rgb(178, 174, 174);
  font-family: "Noto Sans", sans-serif;
  padding: 0 16px;
}
.customDate:hover {
  border-color: #005DA8;
}
