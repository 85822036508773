.custom-seats {
  overflow: auto;
  height: 38rem;
  padding: 0px 20px;
}
.custom-seats .seats-header {
  border-top: 2px solid #000;
  margin: 0rem;
}
.custom-seats .seats-header .seat-heading {
  display: flex;
  justify-content: center;
}
.custom-seats .path {
  text-align: center;
  background-color: #FFA500;
  display: inline-block;
  color: #61505B;
  padding: 5px 30px;
  font-size: 27px;
  margin: 0rem;
}
.custom-seats .path-line {
  width: 100%;
  margin-top: 0rem;
  display: grid;
  grid-template-columns: 1.5fr 2fr 1fr;
}
.custom-seats .path-line .box1 {
  height: 40px;
  border: 1px solid #000;
  border-left: none;
}
.custom-seats .path-line .box2 {
  height: 40px;
  border: 1px solid #000;
}
.custom-seats .path-line .box3 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-seats .path-line .box3 .entrance {
  text-align: center;
  font-size: 16px;
}
.custom-seats .seats-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1.5fr 2fr;
  margin: 20px 0rem;
}
.custom-seats .seats-grid .seat-box {
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 0.5rem;
}
.custom-seats .seats-grid .seat-box .seat label {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #000;
  color: #000;
}
.custom-seats .seats-grid .seat-box .img {
  max-width: 20px;
}
.custom-seats .seats-grid .seat-box-1 {
  grid-template-columns: 1fr 1fr 1fr;
}
.custom-seats .cabin-box {
  width: 100%;
  display: grid;
  grid-template-columns: 1.5fr 2fr;
}
.custom-seats .cabin-box .box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.custom-seats .cabin-box .box .cabin {
  font-size: 16px;
}
.custom-seats .cabin-box .box .tablee {
  font-size: 17px;
  background-color: rgba(85, 85, 85, 0.555);
  padding: 5px 10px;
  border-radius: 5px;
  border: 3px solid rgb(11, 216, 11);
}

.cl-custom-check {
  display: none;
}
.cl-custom-check + .cl-custom-check-label {
  /* Unchecked style  */
  background-color: #ccc;
  color: #fff;
  padding: 5px 10px;
  font-family: sans-serif;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-radius: 4px;
  display: inline-block;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: all 0.6s ease;
}
.cl-custom-check + .cl-custom-check-label.red {
  background-color: red;
  pointer-events: none;
}
.cl-custom-check + .cl-custom-check-label.blue {
  background-color: #005DA8;
  pointer-events: none;
}
.cl-custom-check:checked + .cl-custom-check-label {
  /* Checked style  */
  background-color: #0a0;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.uniqclvalue {
  width: 25rem;
  height: 15rem;
  background-color: #fff;
  position: fixed;
  z-index: 999999;
  top: 16rem;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 1s ease-in-out;
  display: none;
}
.uniqclvalue #displayvalue {
  font-size: 7rem;
  font-weight: 700;
  color: #0a0 !important;
}
.uniqclvalue .cust-btn {
  background-color: #005DA8;
  color: #fff;
  padding: 6px 30px;
}


/* jsdfhsjkadf  */
.custom-seats.acstd98 .cabin-box .box {
  justify-content: center;
}

.custom-seats.acstd98 .entrance-box {
  width: 100%;
  margin-top: 0rem;
  display: grid;
  grid-template-columns: 1.5fr 2fr 1fr;
  padding: .5rem 0rem;

}
.custom-seats.acstd98 .entrance-box .box1{
display: flex;
justify-content: center;
align-items: center;
}

.custom-seats.acstd98 .entrance-box .box1 .entrance{
font-size: 1rem;
margin: 0rem;
}
.custom-seats.acstd98 .seats-grid {
  grid-template-columns: 1.3fr 2fr;
}
.custom-seats.acstd98 .seats-grid .seat-box {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.custom-seats.acstd98 .seats-grid .seat-box-1 {
  grid-template-columns: 1fr 1fr 1fr;
}
/* jsdfhsjkadf  */